import NavbarVetsandTechsDashboard from "../../../Navbars/NavbarVetsandTechsDashboard";
import { FaUserDoctor } from "react-icons/fa6";
import { useState, useEffect } from "react";
import CatDP from "../../../1_MediaAssets/OtherBasics/cat.jpg";
import { MdArrowRightAlt } from "react-icons/md";
import {
  doc,
  getDoc,
  getFirestore,
  query,
  where,
  collection,
  getDocs,
  deleteDoc 
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoadingScreen from "../../../LoadingScreen/index.js";
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";

const AppointmentsVetsandTechs = () => {
  const db = getFirestore();
  const [allAppointments, setAllAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("Upcoming");
  const navigate = useNavigate();
  const auth = getAuth();
  const deleteAppointment = async (appointmentId) => {
    try {
      // Eliminar el appointment de la base de datos
      await deleteDoc(doc(db, "Appointments", appointmentId));
  
      // Actualizar el estado para eliminar el appointment de la lista
      setAllAppointments((prevAppointments) =>
        prevAppointments.filter((appointment) => appointment.id !== appointmentId)
      );
  
      console.log(`Appointment ${appointmentId} deleted successfully`);
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };
  const navigateDoctor = (appointment) => {
    navigate(`/AppointmentRoomVetsandTechs/${appointment.id}`, {
      state: { appointmentId: appointment.id },
    });
  };

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        let doctorSnap = null;

        // Query in Technician collection first
        const technicianQuery = query(
          collection(db, "Doctors/Technician/Technician"),
          where("uid", "==", userId)
        );
        const technicianSnap = await getDocs(technicianQuery);

        if (!technicianSnap.empty) {
          doctorSnap = technicianSnap;
        } else {
          // If not found in Technician, query in Veterinarian collection
          const veterinarianQuery = query(
            collection(db, "Doctors/Veterinarian/Veterinarian"),
            where("uid", "==", userId)
          );
          const veterinarianSnap = await getDocs(veterinarianQuery);

          if (!veterinarianSnap.empty) {
            doctorSnap = veterinarianSnap;
          } else {
            console.error(
              "User not found in Technician or Veterinarian collections"
            );
            setLoading(false);
            return;
          }
        }

        // Get the first document (there should only be one match per user)
        const userDocRef = doc(db, doctorSnap.docs[0].ref.path);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          console.error("User document not found!");
          setLoading(false);
          return;
        }

        const userData = userDocSnap.data();
        const appointmentIds = userData.Appointments || [];
        const allAppointmentsData = [];

        // Using Promise.all to fetch all appointments in parallel
        const appointmentPromises = appointmentIds.map(async (appointmentId) => {
          const appointmentDocRef = doc(db, "Appointments", appointmentId);
          const appointmentDocSnap = await getDoc(appointmentDocRef);

          if (appointmentDocSnap.exists()) {
            const appointmentData = appointmentDocSnap.data();
            appointmentData.id = appointmentId;

            // Confirm that ParentRefID and PetRefID are not undefined before continuing
            if (!appointmentData.ParentRefID || !appointmentData.PetRefID) {
              console.error(
                "Missing ParentRefID or PetRefID in appointmentData:",
                appointmentData
              );
              return null; // Skip this iteration if either field is missing
            }

            // Fetch Parent and Pet data in parallel
            try {
              const parentDocRef = doc(db, "Parents", appointmentData.ParentRefID);
              const petsCollectionRef = collection(parentDocRef, "pets");
              const petDocRef = doc(petsCollectionRef, appointmentData.PetRefID);

              const petDocSnap = await getDoc(petDocRef);
              if (petDocSnap.exists()) {
                const petData = petDocSnap.data();
                appointmentData.PetName = petData.Name || "Unknown Pet";
                appointmentData.PetAgeYears = petData.AgeYears || "Unknown Age";
                appointmentData.PetAgeMonths = petData.AgeMonths || "Unknown Age";
              } else {
                appointmentData.PetName = "Unknown Pet";
                appointmentData.PetAgeYears = "Unknown Age";
                appointmentData.PetAgeMonths = "Unknown Age";
              }
            } catch (error) {
              console.error("Error fetching pet document:", error);
            }

            return appointmentData;
          } else {
            console.log("No such appointment document with ID:", appointmentId);
            return null;
          }
        });

        // Await all promises to be resolved
        const appointmentsData = await Promise.all(appointmentPromises);

        // Filter out null values (in case some appointments failed to fetch)
        const validAppointmentsData = appointmentsData.filter(
          (appointment) => appointment !== null
        );

        setAllAppointments(validAppointmentsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, pass userId to fetchUserData
        fetchUserData(user.uid);
      } else {
        // No user is signed in, handle the case
        console.error("No user is signed in");
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const filteredAppointments = allAppointments.filter((appointment) => {
    if (filter === "Upcoming") {
      return appointment.status === "Upcoming";
    } else if (filter === "Past") {
      return appointment.past === true && (appointment.status === "Incomplete" || appointment.status === "Complete");
    } else if (filter === "Action Needed") {
      return appointment.status === "Complete" || appointment.status === "Incomplete" && appointment.past === false;
    }
    return false;
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100vw",
      }}
    >
      <div className="dashboardmain">
        <NavbarVetsandTechsDashboard />

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            background: "#fff",
            borderRadius: "10px",
            width: "74%",
            marginTop: "50px",
            boxShadow:
              "0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
              padding: "15px",
            }}
          >
            <h4
              style={{
                padding: "0",
                margin: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "900",
              }}
            >
              <FaUserDoctor
                style={{
                  fontSize: "30px",
                  margin: "0px 10px 0px 5px",
                  color: "#0849c1",
                }}
              />
              Appointments
            </h4>
            <p>Manage all appointments and pet parent reviews.</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "20px", marginRight: "10px" }}>
              <button style={{  marginRight: "10px" }} onClick={() => setFilter("Upcoming")}>Upcoming</button>
              <button style={{marginRight: "10px" }} onClick={() => setFilter("Past")}>Past</button>
              <button style={{  marginRight: "10px" }} onClick={() => setFilter("Action Needed")}>Action Needed</button>
            </div>

            {filteredAppointments.map((appointment, index) => (
              <div
                className="PatientDiv"
                key={index}
                onClick={() => {
                  navigateDoctor(appointment);
                }}
              >
                <div className="SubPatientDiv">
                  <img
                    className="DPhider"
                    src={appointment.profilePictureURL || CatDP}
                    alt="PetParentDP"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "100px",
                      marginLeft: "2vw",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Inter, sans-serif",
                      textTransform: "capitalize",
                      minWidth: '120px'
                    }}
                  >
                    {appointment.ParentName}
                  </p>

                  <img
                    className="DPhider"
                    src={CatDP}
                    alt="CatDP"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "100px",
                      marginLeft: "2vw",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "13px",
                      fontFamily: "Inter, sans-serif",
                    }}
                  >
                    {appointment.PetName || "Pet Name"} <br />{" "}
                    <span style={{ fontSize: "12px", color: "grey" }}>
  {`${appointment.PetAgeYears || "0"} year(s) ${appointment.PetAgeMonths || "0"} month(s) old`}
</span>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <p
                    style={{
                      fontSize: "13px",
                      marginLeft: "2vw",
                      fontFamily: "Inter, sans-serif",
                    }}
                  >
                    {appointment.AppointmentDate || "Jan 21, 2023"}
                    <br />{" "}
                    <span style={{ fontSize: "12px", color: "grey" }}>
                      {appointment.AppointmentTime || "04:30 PM - 05:30 PM"}
                    </span>
                    <br />{" "}
                    <span
                      className={appointment.status}
                      style={{ fontSize: "12px", color: "grey" }}
                    >
                      {appointment.status || "N/A"}
                    </span>
                  </p>
                </div>
                <button
        onClick={(e) => {
          e.stopPropagation(); // Evitar que el evento click también navegue
          deleteAppointment(appointment.id);
        }}
        style={{
          marginLeft: "auto",
          marginRight: "10px",
          background: "none",
          border: "none",
          color: "red",
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        ✖
      </button>
                <i
                  className="fas fa-chevron-right"
                  style={{
                    color: "#5a5ad9",
                    fontSize: "16px",
                    margin: "0 20px 0 0",
                  }}
                ></i>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsVetsandTechs;
